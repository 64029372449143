import { createAsync } from '@solidjs/router';
import { onMount, Show, Suspense } from 'solid-js';
import { useAnalytics } from '@troon/analytics';
import { AccessProductsCtx, getAccessProducts } from '../providers/card';
import type { RouteSectionProps } from '@solidjs/router';

export default function CardsPageLayout(props: RouteSectionProps) {
	const data = createAsync(() => getAccessProducts({}), { deferStream: true });
	const analytics = useAnalytics();

	onMount(() => {
		analytics?.startSessionRecording({ sampling: true });
	});

	return (
		<Suspense>
			<Show when={data()?.products}>
				{(products) => <AccessProductsCtx.Provider value={products}>{props.children}</AccessProductsCtx.Provider>}
			</Show>
		</Suspense>
	);
}
